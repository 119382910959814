import { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { addLoadingContent, removeLoadingContent } from "reducers/LoadingOverlay";
import { Get } from "utils/axios";
import { requestSuccess, requestError } from "utils/requestHandler";
import { convertObjectToBase64 } from "utils/objToBase64";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      tags: [],
      categories: [],
      variantCombinations: [],
      packages: [],
      operators: [
        { value: "and", label: "AND" },
        { value: "or", label: "OR" },
        { value: "none", label: "NONE" }
      ],
      type2: [
        { value: "amount", label: "Amount" },
        { value: "pv", label: "PV" },
        { value: "quantity", label: "Quantity" },
        { value: "criteria", label: "Criteria" }
      ],
      type1: [
        { value: "category", label: "Category" },
        { value: "product", label: "Product" },
        { value: "package", label: "Package" },
        { value: "tag", label: "Tag" },
        { value: "total_amount", label: "Total Amount" },
        { value: "total_pv", label: "Total PV" },
        { value: "criteria", label: "Criteria" }
      ]
    };

    load = (param) => {
      if (param) {
        this.props.addLoadingContent();
      } else {
        this.props.removeLoadingContent();
      }
    };

    onChangeHOC = (key, val) => this.setState({ [key]: val });

    getPackages = () =>
      Get(
        `/sites/${this.props.siteInfo.id}/packages?query=${convertObjectToBase64({
          isDisabledPagination: true,
          hideHistories: true,
          hideProducts: true,
          filter: {},
          sorter: {}
        })}`,
        this.getPackagesSuccess,
        requestError,
        this.load
      );
    getPackagesSuccess = (payload) => {
      this.setState({
        packages: _.sortBy(payload, ["code"])
      });
    };

    getVariantCombinations = () =>
      Get(
        `/sites/${this.props.siteInfo.id}/variant_combinations`,
        this.getVariantCombinationsSuccess,
        requestError,
        this.load
      );
    getVariantCombinationsSuccess = (payload) => {
      this.setState({
        variantCombinations: _.sortBy(payload, ["stock_code"])
      });
    };

    getCategories = () =>
      Get(
        `/sites/${this.props.siteInfo.id}/product-categories`,
        this.getCategoriesSuccess,
        this.getCategoriesError,
        this.load
      );
    getCategoriesSuccess = (payload) =>
      this.setState({
        categories: _.sortBy(
          payload.map((val) => ({ ...val, effectiveName: val.name[this.props.selectedLanguage] })),
          ["display_sequence"]
        )
      });
    getCategoriesError = (error) => this.requestError(error);

    getTags = () => Get(
        `/sites/${ this.props.siteInfo.id }/product-tags`,
        this.getTagsSuccess,
        this.getTagsError,
        this.load
      )
    getTagsSuccess = payload => this.setState({ tags: payload })
    getTagsError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onChangeHOC={this.onChangeHOC}
          getTags={this.getTags}
          getPackages={this.getPackages}
          getCategories={this.getCategories}
          getVariantCombinations={this.getVariantCombinations}
        />
      );
    };
  }

  const mapStateToProps = (state) => ({
    data: state,
    selectedLanguage: state.ProfileReducer.profile.preferred_language,
    siteInfo: state.ClientReducer.siteInfo,
    colors: state.ClientReducer.clientInfo.styles?.colors
  });
  return connect(mapStateToProps, { addLoadingContent, removeLoadingContent })(WithHOC);
};

export default HOC;
