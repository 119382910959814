import { useState, useEffect } from "react";
import { Select, Form, InputNumber } from "antd";
import _ from "lodash";

import WithHOC from "./actions";
import "./index.scss";
import { logicToText } from "./logicToText";
const { Option } = Select;

const requiredRules = { required: true, message: "This is required" };
const ValueInputContainer = (props) => {
  const { name, valueType, valueOptions } = props;
  switch (valueType) {
    case "total_amount":
    case "total_pv":
    case "amount":
    case "pv":
    case "quantity":
      return (
        <Form.Item name={name} rules={[requiredRules]}>
          <InputNumber placeholder={`Please enter ${valueType.replaceAll("_", " ")}`} />
        </Form.Item>
      );
    case "package":
      return (
        <Form.Item name={name} rules={[requiredRules]}>
          <Select
            showSearch
            placeholder={`Please select ${valueType.replaceAll("_", " ")}`}
            filterOption={(input, option) => option.children.join().toLowerCase().includes(input.toLowerCase())}
          >
            {_.map(valueOptions["packages"], (option) => (
              <Option value={option.id}>
                {option.code}&emsp;{option.name.en}
              </Option>
            ))}
          </Select>
        </Form.Item>
      );
    case "product":
      return (
        <Form.Item name={name} rules={[requiredRules]}>
          <Select
            showSearch
            placeholder={`Please select ${valueType.replaceAll("_", " ")}`}
            filterOption={(input, option) => option.children.join().toLowerCase().includes(input.toLowerCase())}
          >
            {_.map(valueOptions["variantCombinations"], (option) => (
              <Option value={option.id}>
                {option.stock_code}&emsp;{option.site_product?.name.en} {option.variant_level1?.name}{" "}
                {option.variant_level2?.name ?? ""}
              </Option>
            ))}
          </Select>
        </Form.Item>
      );
    case "category":
      return (
        <Form.Item name={name} rules={[requiredRules]}>
          <Select
            showSearch
            placeholder={`Please select ${valueType.replaceAll("_", " ")}`}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          >
            {_.map(valueOptions["categories"], (option) => (
              <Option value={option.id}>{option.name.en}</Option>
            ))}
          </Select>
        </Form.Item>
      );
    case "tag":
      return (
        <Form.Item name={name} rules={[requiredRules]}>
          <Select
            showSearch
            placeholder={`Please select ${valueType.replaceAll("_", " ")}`}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          >
            {_.map(valueOptions["tags"], (option) => (
              <Option value={option.id}>{option.name.en}</Option>
            ))}
          </Select>
        </Form.Item>
      );
    case "criteria":
      return <CriteriaCombinations {...props} />;
    default:
      return <></>;
  }
};

const TypeContainer = (props) => {
  const { form, name, type, level, typeOption, doneInitial, operator } = props;
  const valueType = Form.useWatch([...name, `criteria_${type}`, "type"], form);
  const valueType1 = Form.useWatch([...name, "criteria_1", "type"], form);
  const [options, setOptions] = useState(typeOption);

  useEffect(() => {
    if (type === 2) {
      doneInitial && form.setFieldValue([...name, `criteria_2`, "type"], undefined);
    } else {
      doneInitial && form.setFieldValue([...name, `criteria_1`, "value"], undefined);
    }
  }, [valueType1]);

  if (type === 2) {
    if (["total_amount", "total_pv"].includes(valueType1)) {
      const tmp = _.filter(typeOption, { value: "criteria" });
      if (!_.isEqual(tmp, options)) {
        setOptions(tmp);
      }
    } else if (!["criteria"].includes(valueType1)) {
      const tmp = _.filter(typeOption, (option) => option.value !== "criteria");
      if (!_.isEqual(tmp, options)) {
        setOptions(tmp);
      }
    } else {
      if(operator === 'and'){
        if (!_.isEqual(typeOption, options)) {
          setOptions(typeOption);
        }
      } else {
        const tmp = _.filter(typeOption, { value: "criteria" });
        if (!_.isEqual(tmp, options)) {
          setOptions(tmp);
        }
      }
    }
  }

  return (
    <div className={`combination-type-container type-${type}`}>
      <Form.Item
        name={[...name, `criteria_${type}`, "type"]}
        rules={[requiredRules]}
        label={`Criteria ${level}`}
        className="mb-2"
      >
        <Select options={options} placeholder="Please select a type of criteria" />
      </Form.Item>

      <ValueInputContainer {...props} valueType={valueType} name={[...name, `criteria_${type}`, "value"]} />
    </div>
  );
};

const CriteriaCombinations = (props) => {
  const {
    name = ["combinations"],
    level = "",
    operators,
    type1,
    type2,
    tags,
    categories,
    variantCombinations,
    packages,
    getTags,
    getPackages,
    getCategories,
    getVariantCombinations
  } = props;
  const { formRef } = props.item;
  const form = props[formRef];
  const [doneInitial, setDoneInitial] = useState(false);
  const [operatorOptions, setOperatorOptions] = useState(operators);
  const operator = Form.useWatch([...name, "operators"], form);
  const valueType1 = Form.useWatch([...name, "criteria_1", "type"], form);
  const combinations = Form.useWatch("combinations", form);
  useEffect(() => {
    if (!level) {
      getTags();
      getPackages();
      getCategories();
      getVariantCombinations();
    }
  }, []);

  useEffect(() => {
    if (
      (props.showUpdateModal && _.isEqual(combinations, props.selectedPromotion.combinations)) ||
      props.showCreateModal
    ) {
      setDoneInitial(true);
    }
  }, [combinations]);

  useEffect(() => {
    if (["category", "product", "package", "tag"].includes(valueType1)) {
      const tmp = _.filter(operators, (option) => option.value === "and");
      if (!_.isEqual(tmp, operatorOptions)) {
        setOperatorOptions(tmp);
      }
      doneInitial && form.setFieldValue([...name, "operators"], "and");
    } else {
      if (!_.isEqual(operators, operatorOptions)) {
        setOperatorOptions(operators);
      }
      doneInitial && form.setFieldValue([...name, "operators"], "none");
    }
  }, [valueType1]);

  useEffect(() => {
    if (operator === "none") {
      doneInitial && form.setFieldValue([...name, "criteria_2"], undefined);
    }
  }, [operator]);

  return (
    <>
      <div className="combination-container">
        <TypeContainer
          {...props}
          form={form}
          name={name}
          type={1}
          level={level ? level + "-1" : "1"}
          typeOption={type1}
          doneInitial={doneInitial}
          valueOptions={{ categories: categories, variantCombinations: variantCombinations, packages: packages, tags: tags }}
        />
        {valueType1 && (
          <>
            <div className="combination-type-container operator">
              <Form.Item name={[...name, "operators"]} rules={[requiredRules]} label="Operator">
                <Select options={operatorOptions} placeholder="Please select an operator" />
              </Form.Item>
            </div>
            {operator !== "none" && (
              <TypeContainer
                {...props}
                form={form}
                name={name}
                type={2}
                level={level ? level + "-2" : "2"}
                typeOption={type2}
                doneInitial={doneInitial}
                operator={operator}
              />
            )}
          </>
        )}
        {!level && (
          <div className="combination-type-container logic-string">
            {logicToText({
              ...(combinations ?? {}),
              options: { category: categories, product: variantCombinations, package: packages }
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default WithHOC(CriteriaCombinations);
