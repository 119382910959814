import { compose } from "redux"

import WithHOC from "./actions"
import CoreRenderFunction from "DisplayCore"
import { OrderBackdateLayout } from "PageLayout/OrderManagement/OrderBackdate"
import { useEffect } from "react"
import { Form } from "antd"
import _, { create } from "lodash"
import { keys } from "localforage"

const OrderBackdate = (props) => {
  const {
    can_read,
    can_create,
    can_update,
    can_delete
  } = props.modulePermissions

  const {
    query,
    onChangeHOC,
    exportToCSV,
    getBackdates,
    getCUOs,
    submitBackdate
  } = props

  const [filterForm] = Form.useForm()
  const [createForm] = Form.useForm()

  useEffect(() => {
    getCUOs()
  }, [getCUOs])

  useEffect(() => {
    getBackdates()
  }, [query, getBackdates])

  useEffect(() => {
    if(props.showCreateModal) {
      createForm.resetFields()
      onChangeHOC('selectedCUOs', { keys: [], rows: [] })
      onChangeHOC('uploadedDocument', [])
      onChangeHOC('uploadedDocumentInfo', {})
    }
  }, [props.showCreateModal])

  const onClickAction = (actionKey, ...args) => {
    switch(actionKey) {
      case 'CLEAR_FILTER':
        onChangeHOC('query', { filter: {}, sorter: {} })
        filterForm.resetFields()
        break
      case 'EXPORT_TO_CSV':
        exportToCSV()
        break
      case 'OPEN_CREATE_MODAL':
        onChangeHOC('showCreateModal', true)
        break
      case 'CLOSE_CREATE_MODAL':
        onChangeHOC('showCreateModal', false)
        break
      case 'OPEN_CUO_OPTIONS':
        onChangeHOC('showCUOOptionsModal', true)
        break
      case 'CLOSE_CUO_OPTIONS':
        onChangeHOC('showCUOOptionsModal', false)
        break
      case 'ON_SUBMIT_CUO_OPTIONS':
        onChangeHOC("selectedCUOs", args[0])
        onChangeHOC("showCUOOptionsModal", false)
        break
      case 'UPLOAD_DOCUMENT':
        if(args[0] && args[0].length > 0) {
          const reader = new FileReader()
          reader.onload = () => {
            onChangeHOC('uploadedDocumentInfo', {
              base64: reader.result,
              file_name: args[0][0].filename,
              file_type: args[0][0].fileType
            })
          }
          reader.readAsDataURL(args[0][0].file)
          onChangeHOC('uploadedDocument', [])
        }
        break
      case 'REMOVE_UPLOADED_DOCUMENT':
        onChangeHOC('uploadedDocumentInfo', {})
        break
      default: return
    }
  }

  const hideComponent = ( actionKey ) => {
    switch( actionKey ){
      case 'noCreatePermission':
        return !can_create
      case 'noUpdatePermission':
        return !can_update
      case 'noDeletePermission':
        return !can_delete
      case 'noDocumentUploaded':
        return _.isEmpty(props.uploadedDocumentInfo)
      default:
    }
  }

  const onSubmitCreateForm = (formData) => {
    const data = {
      document: {
        number: formData.document_no,
        name: props.uploadedDocumentInfo.file_name,
        base64: props.uploadedDocumentInfo.base64
      },
      client_user_orders: props.selectedCUOs.keys
    }

    submitBackdate(data)
  }

  return (
    <>
      {
        can_read && (
          <CoreRenderFunction
            {...props}
            item={OrderBackdateLayout.content}
            filterForm={filterForm}
            createForm={createForm}
            onSubmitCreateForm={onSubmitCreateForm}
            onClickAction={onClickAction}
            hideComponent={hideComponent}
          />
        )
      }
    </>
  )
}

export default compose(WithHOC)(OrderBackdate)