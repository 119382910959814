export const FilterForm = {
	showArrow: false,
	title: {
		type: "div",
		className: "d-flex",
		children: [
			{
				type: "icon",
				antIcon: "FilterOutlined",
				style: { marginRight: "6px", paddingTop: "4px" },
			},
			{
				type: "text",
				content: "{{FILTER_BY}}",
			},
		],
	},
	children: [
		{
			type: 'form',
			formRef: 'filterForm',
			onFinish: 'onSubmitFilterForm',
			children: [
				{
					type: 'div',
					className: 'w-100',
					children: [
						{
							type: 'row',
							children: [
								{
									type: 'col',
									span: 12,
									children: [
										{
											type: 'input',
											label: '{{BACKDATE_DOCUMENT_NUMBER}}',
											name: 'document_no__contains',
											inputConfig: {
												placeholder: '{{BACKDATE_DOCUMENT_NUMBER}}',
												size: 'large'
											}
										}
									]
								}
							]
						},
						{
							type: 'div',
							className: 'd-flex justify-content-end',
							children: [
								{
									type: "button",
									buttonType: "text",
									className: "mr-2 my-1",
									actionKey: "CLEAR_FILTER",
									children: [
										{
											type: "text",
											content: "{{CLEAR}}"
										},
									],
								},
								{
									type: "button",
									htmlType: "submit",
									buttonType: "text",
									className: "custom-color my-1",
									style: {
										backgroundColor: "$quaternary",
										color: "#ffffff",
									},
									children: [
										{
											type: "text",
											content: "{{SEARCH}}"
										},
									],
								},
							]
						}
					]
				}
			]
		}
	]
}