import _ from "lodash";
import moment from "moment";
import { DownloadFile, Get, Post } from "utils/axios";
import { convertObjectToBase64 } from "utils/objToBase64";
import { requestError } from "utils/requestHandler";

const { Component } = require("react");
const { connect } = require("react-redux");
const { addLoadingContent, removeLoadingContent } = require("reducers/LoadingOverlay");

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      query: {
        filter: {},
        sorter: {},
        page: 1
      },
      backdates: [],
      backdateMetadata: {},
      clientUserOrders: [],
      selectedCUOs: { keys: [], rows: [] },
      cuoOptionsColumns: [
        {
          title: "Invoice Number",
          filterItem: true,
          dataIndex: "invoice_number"
        },
        {
          title: "Member ID",
          filterItem: true,
          dataIndex: "client_user.username",
          render: (text, record) => record.client_user.username,
        },
        {
          title: "Member Name",
          filterItem: true,
          dataIndex: "client_user.full_name",
          render: (text, record) => record.client_user.full_name,
        },
        {
          title: "Invoice Date",
          filterItem: true,
          dataIndex: "created_at",
          render: (text, record) => moment(record.created_at).format("YYYY-MM-DD")
        },
        {
          title: "Amount (MYR)",
          dataIndex: "total_amount",
        },
        {
          title: "PV",
          dataIndex: "client_user_point_logs",
          render: (text, record) => _.sumBy(record.client_user_point_logs, 'amount') ?? 0,
        }
      ],
      uploadedDocument: [],
      uploadedDocumentInfo: {},
      showCreateModal: false,
      showCUOOptionsModal: false,
    }

    load = param => {
      if( param ){
        this.props.addLoadingContent()
      } else {
        this.props.removeLoadingContent()
      }
    }

    onChangeHOC = (key, val) => this.setState({ [key]: val })

    getBackdates = () => Get(
      `/sites/${ this.props.siteInfo.id }/client_user_order_backdates?query=${ convertObjectToBase64({ ...this.state.query }) }`,
      (response) => this.setState({
        backdates: response.rows,
        backdateMetadata: response.meta
      }),
      requestError,
      this.load
    )

    getCUOs = () => Get(
      `/sites/${ this.props.siteInfo.id }/orders?query=${ convertObjectToBase64({ filter: { created_at__between: [moment().startOf('month'), moment().date(4).endOf('day')], invoice_number__is_not: null, status__in: ["payment-success", "to-repay", "to-reship", "collected", "packaged", "delivered", "shipped"] }, sorter: {}, isDisabledPagination: true }) }`,
      (response) => this.setState({ clientUserOrders: response.rows }),
      requestError,
      this.load
    )

    exportToCSV = () => DownloadFile(
      `/sites/${ this.props.siteInfo.id }/client_user_order_backdates/exportCSV?query=${ convertObjectToBase64({ ...this.state.query }) }`,
      'Order Backdates.csv',
      requestError,
      this.load
    )

    submitBackdate = (values) => Post(
      `/sites/${ this.props.siteInfo.id }/client_user_order_backdates`,
      values,
      (response) => {
        this.setState({ showCreateModal: false })
        this.getBackdates()
        this.getCUOs()
      },
      requestError,
      this.load
    )

    render = () => (
      <WrappedComponent
        {...this.props}
        {...this.state}
        onChangeHOC={this.onChangeHOC}
        getBackdates={this.getBackdates}
        getCUOs={this.getCUOs}
        exportToCSV={this.exportToCSV}
        submitBackdate={this.submitBackdate}
      />
    )
  }

  const mapStateToProps = (state) => ({
    data: state,
    colors: state.ClientReducer.clientInfo.styles?.colors,
    siteInfo: state.ClientReducer.siteInfo
  })

  return connect(mapStateToProps, { addLoadingContent, removeLoadingContent })(WithHOC)
}

export default HOC