import _ from "lodash";
import { thousandSeperatorFormatter } from "utils";
export const logicToText = ({ criteria_1 = {}, criteria_2 = {}, operators = "", options }) => {
  const string1 = `${processType(criteria_1, options)}`;
  const string2 = !["none", ""].includes(operators)
    ? ` ${operators.toUpperCase()} ${processType(criteria_2, options)}`
    : "";
  return !_.isEmpty(string1.replaceAll(" ", "")) ? `(${string1}${string2})` : "";
};

const processType = ({ type = "", value = "" }, options) => {
  switch (type) {
    case "total_amount":
      return `Total Amount: RM ${thousandSeperatorFormatter(value)}`;
    case "total_pv":
      return `Total Points: ${value}PV`;
    case "amount":
      return `Amount: RM ${thousandSeperatorFormatter(value)}`;
    case "pv":
      return `Points: ${value}PV`;
    case "quantity":
      return `Purchase Quantity: ${value}`;
    case "package":
      return `Package ${_.find(options[type], { id: value })?.code ?? ""}`;
    case "product":
      return `Product ${_.find(options[type], { id: value })?.stock_code ?? ""}`;
    case "category":
      return `Category ${_.find(options[type], { id: value })?.name?.en ?? ""}`;
    case "criteria":
      return logicToText({ ...value, options: options });
    default:
      return "";
  }
};
